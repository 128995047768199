<template>
  <div class="ps-section__right">
      <div class="ps-form__header">
        <h3>Create Listing</h3>
      </div>
      <ListingForm ref="listingForm"></ListingForm>
  </div>
</template>
<script>
import ListingForm from "./ListingForm";

export default {
  name: "CreateListing",
  components: {ListingForm},
};
</script>
